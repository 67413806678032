import React, {useEffect, useState} from 'react';
import CardReview from "@/app/components/atomic/molecules/CardReview";

export const BASE_API: string = `${process.env.NEXT_PUBLIC_RM_BASE_API}/api`

interface Review {
    rate: number;
    firstname: string;
    review_date: string;
    review: string;
}

interface Props {
    reviewNumber: number;
    minStars?: number;
    colsMd?: number;
    displayDate?: boolean;
    fixedHeight?: boolean
}

export default function GridCardReview({reviewNumber, displayDate = false, fixedHeight = false, minStars = 3, colsMd = 1}: Props) {
    const [reviews, setReviews] = useState<Review[]>([]);

    useEffect(() => {
        const getLastReviews = async () => {
            const url = `${BASE_API}/reviews/get-last-reviews/${reviewNumber}/${minStars}`;
            try {
                const response = await fetch(url);
                if (response.ok) {
                    const data = await response.json();

                    if (data.reviews.length > 1) {
                        setReviews(data.reviews);
                    }
                    setReviews(getDefaultReviews());
                }
            } catch (error) {
                setReviews(getDefaultReviews());
            }
        };

        getLastReviews();
    }, [reviewNumber, minStars]);

    const getDefaultReviews = (): Review[] => [
        {
            rate: 5,
            firstname: 'Marc',
            review_date: '2023-07-31 17:28:13',
            review:
                "Tout s'est bien passé, y compris le suivi de mon dossier. J'ai pu comparer et sélectionner un assureur sérieux et compétitif.",
        },
        {
            rate: 5,
            firstname: 'Edouard',
            review_date: '2023-02-22 13:11:49',
            review:
                "Excellent suivi avec une prise en charge de A à Z de mon changement d'assurance. Équipe très pro à l'écoute et joignable très facilement. " +
                "Plus de 4000e d'économies, je n'y croyais pas et le contrat est bien identique à celui de ma banque. Je recommande vivement!",
        },
        {
            rate: 4,
            firstname: 'Nicolas',
            review_date: '2023-02-17 15:28:36',
            review:
                "Très bien, par contre, il serait opportun de pouvoir déposer son échéancier de prêt pour pré-analyse de l'assureur afin de tarifer au plus " +
                "juste.",
        },
        {
            rate: 5,
            firstname: 'Michele',
            review_date: '2023-07-02 21:10:22',
            review:
                'Une application bien faite et des réponses aux messages personnalisées et rapides. Une offre dense, suivi téléphonique sérieux et efficace.',
        },
        {
            rate: 5,
            firstname: 'Melanie',
            review_date: '2023-03-07 21:05:29',
            review: 'Très bien, sérieux, réactif et compétitif.',
        },
        {
            rate: 5,
            firstname: 'Vincent',
            review_date: '2022-11-09 07:09:15',
            review:
                'Site simple, efficace et joignable par téléphone ce qui est rare de nos jours. Pour ma part une économie de 50 euros par mois pour ma ' +
                'nouvelle assurance.',
        },
        {
            rate: 5,
            firstname: 'Francine',
            review_date: '2023-09-06 19:52:11',
            review: 'Équipe très pro, à l écoute du client, très réactive. Que du bonheur de vous avoir contacté. Merci.',
        },
        {
            rate: 4,
            firstname: 'Wilfrid',
            review_date: '2023-02-09 18:20:21',
            review: 'Facile, réactif et joignable.',
        },
        {
            rate: 5,
            firstname: 'Alain',
            review_date: '2022-10-07 20:37:14',
            review: "Très bien et bonne communication pour le moment, dossier en attente d'acceptation par la banque.",
        },
        {
            rate: 5,
            firstname: 'Sabrina',
            review_date: '2022-12-13 18:53:42',
            review: 'Très bien et offre irréprochable.',
        },
    ];

    return (
        <div className="flex flex-col md:flex-row gap-y-5 gap-x-[30px]">
            {Array.from(Array(colsMd).keys()).map((colnumber) => (
                <div
                    key={colnumber}
                    className={`w-full flex flex-col gap-y-5 md:w-1/${colsMd}`}
                >
                    {reviews && reviews
                        .filter((_, index) => index % colsMd === colnumber && index < reviewNumber)
                        .map((reviews) => (
                            <CardReview
                                key={reviews.review_date}
                                review={reviews}
                                displayDate={displayDate}
                                fixedHeight={fixedHeight}
                            />
                        ))}
                </div>
            ))}
        </div>
    );
};
