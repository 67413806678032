'use client'
import Image from "next/image"
import TypingWriter from '@/app/components/atomic/TypingWriter'
import GridCardReview from "@/app/components/atomic/organisms/GridCardReview"
import StarsRating from "@/app/components/atomic/StarsRating";
import InsurerSection from "@/app/components/homepage/InsurerSection";
import StepsSection from "@/app/components/homepage/StepsSection";
import SubProductsSection from "@/app/components/homepage/SubProductsSection";
import HeadProductsSection from "@/app/components/homepage/HeadProductsSection";
import PopularPagesSection from "@/app/components/homepage/PopularPagesSection";
import LastPostsSection from "@/app/components/homepage/LastPostsSection";
import FeaturedHeadlinesSection from "@/app/components/homepage/FeaturedHeadlinesSection";
import AppButton from "@/app/components/atomic/molecules/AppButton";

import React from "react";

export default function Homepage() {
    return (
        <>
            <div className={'flex flex-col mx-auto items-center my-4 gap-y-6 md:my-12 md:gap-y-12 text-blue-700'}>
                <div className={'font-bold text-center'}>
                    <h1 className={'text-2xl md:text-4xl'}>
                        Trouver les meilleures assurances.
                    </h1>

                    <div className={'text-2xl md:text-4xl text-orange-600'}>
                        <TypingWriter speed={100} deleteSpeed={100} words={['Anonyme.', 'Gratuit.', 'Expert.']} waiting={500} />
                    </div>
                </div>

                <div className={'grid grid-cols-2 gap-5 md:flex md:justify-between px-5 md:w-full md:max-w-6xl'}>
                    <HeadProductsSection />
                </div>

                <div className="flex flex-col md:flex-row py-4 md:py-8 gap-4 items-center justify-center">
                    <span className="font-semibold">Nos clients nous font confiance</span>
                    <StarsRating
                        noteFontSize={'text-xl'}
                        starsFontSize={'text-2xl'}
                        noteColorClass={'text-yellow-700'}
                        displayRatingNotation
                        notationOnTheLeft
                        notation={4.7}
                    />
                    <div className="flex items-center justify-center gap-2">
                        <div className="relative object-contain w-8 h-8">
                            <Image
                                className={'object-contain'}
                                src="/images/homepage/logos/avis_verifies.png"
                                alt="avis vérifiés"
                                fill
                                sizes="10vw"
                            />
                        </div>

                        <a
                            href="https://www.avis-verifies.com/avis-clients/reassurez-moi.fr"
                            target="_blank"
                        >
                            1 042 avis sur Avis Vérifiés
                        </a>
                    </div>
                </div>

                <div className={'flex flex-col md:flex-row md:justify-between px-32 py-4 md:py-8 container items-center gap-8 md:gap-0'}>
                    <FeaturedHeadlinesSection />
                </div>

                <div className={'w-full'}>
                    <div className={'bg-blue-700 w-full py-12 flex flex-col items-center gap-10'}>
                        <SubProductsSection />
                    </div>

                    <div className={'bg-blue-200 w-full py-12 flex flex-col justify-between items-center gap-10'}>
                        <InsurerSection />
                    </div>

                    <div className={'flex flex-col items-center gap-10 col-span-2 py-12'}>
                        <StepsSection />
                    </div>
                </div>

                <div className="w-full bg-blue-200 py-12 flex flex-col items-center gap-10">
                    <div className={'flex flex-col items-center gap-10 w-10/12'}>
                        <h2 className={'text-xl md:text-3xl font-semibold'}>
                            Nos clients nous recommandent
                        </h2>

                        <GridCardReview
                            reviewNumber={6}
                            displayDate
                            fixedHeight
                            colsMd={3}
                            minStars={4}
                        />

                        <AppButton
                            isLink
                            href="/avis"
                            size="large"
                            theme="blueSecondary"
                        >
                            Lire nos avis clients
                            <i className="fa-light fa-sm fa-arrow-right" />
                        </AppButton>
                    </div>
                </div>

                <div className={'text-center lg:flex lg:flex-col container px-2 lg:px-12 2xl:px-20 items-center gap-4 pt-7'}>
                    <PopularPagesSection />
                </div>

                <LastPostsSection />
            </div>
        </>
    )
}
