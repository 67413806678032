import {useState} from "react";

export default function PopularPagesSection() {

    const popularPages = [
        {
            title: 'Comment changer de mutuelle santé ?',
            link: '/guide/mutuelle-sante/changer',
        },
        {
            title: 'Quand renégocier son assurance de prêt immobilier ?',
            link: '/guide/assurance-pret-immobilier/renegocier',
        },
        {
            title: 'Quel est le meilleur Plan Épargne Retraite (PER) ?',
            link: '/guide/per/meilleur',
        },
        {
            title: 'Comparateur de rachat de crédit',
            link: '/guide/rachat-credit',
        },
        {
            title: 'Quelle est votre capacité d\'emprunt immobilier ?',
            link: '/guide/pret-immobilier/capacite-emprunt',
        },
        {
            title: 'Tout savoir sur le prix d’une stérilisation pour une chatte ou d’une castration pour un chat !',
            link: '/guide/assurance-chien-chat/sterilisation-chat',
        },
        {
            title: 'Comment obtenir le meilleur taux pour votre crédit auto ? ',
            link: '/guide/credit-consommation/auto',
        },
        {
            title: 'Quand et comment souscrire une assurance annulation voyage ?',
            link: '/guide/assurance-voyage/annulation-voyage',
        },
        {
            title: 'Assurance décès : tarif et comparatif',
            link: '/guide/assurance-deces/prix',
        },
        {
            title: 'Quel est le coût de l\'assurance loyer impayé ?',
            link: '/guide/assurance-loyer-impaye/cout',
        },
    ]

    const [isPopularPagesOpen, setIsPopularPagesOpen] = useState(false)

    return (
        <>
            <h2 className={'text-xl md:text-3xl pb-5'}>Pages populaires</h2>

            <div
                className={'flex lg:flex-wrap lg:justify-center gap-5 overflow-auto lg:overflow-hidden transition-[max-height] ' +
                    (isPopularPagesOpen ? 'lg:max-h-[510px] 2xl:max-h-[320px]' : 'max-h-[150px]')}
            >
                {popularPages?.map((popularPage, index) => {
                    return (
                        <div
                            className={'max-w-1xl'}
                            key={`page-${index}`}
                        >
                            <a
                                href={popularPage.link}
                                className={'w-[220px] h-[150px] rounded-lg border flex flex-col hover:text-blue-500 hover:bg-blue-100 overflow-hidden'}
                            >
                                <div className={'h-5 bg-blue-700 border border-blue-700'}></div>

                                <div className={'text-center px-4 py-7'}>
                                    {popularPage.title}
                                </div>
                            </a>
                        </div>
                    )
                })}
            </div>

            <button
                className={'lg:flex items-center justify-center rounded-full border w-10 h-10 hidden'}
                onClick={() => setIsPopularPagesOpen(!isPopularPagesOpen)}
            >
                <i className={'fa-solid ' + (isPopularPagesOpen ? 'fa-chevron-up' : 'fa-chevron-down')} />
            </button>
        </>
    )
}
