export default function StepsSection() {

    const steps = [
        {
            step: '1',
            title: 'Comparaison',
            description: 'Nos algorithmes comparent des dizaines de contrats par type d’assurance. Les résultats sont accessibles sans laisser ' +
                'votre email ou votre téléphone.',
        },
        {
            step: '2',
            title: 'Conseil',
            description: 'Nous sélectionnons les meilleures offres pour vous en fonction de votre profil afin de vous offrir le meilleur rapport ' +
                'tarif et garanties',
        },
        {
            step: '3',
            title: 'Accompagnement',
            description: 'Toutes les démarches sont 100% en ligne et vous avez un expert dédié pour vous accompagner et répondre à toutes vos questions.',
        },
    ]

    return (
        <>
            <h2 className={'text-xl md:text-3xl font-semibold'}>
                Comment ça marche
            </h2>

            <div className={'flex flex-col items-center gap-10'}>
                {steps.map((step, index) => {
                    return (
                        <div
                            key={`step-${index}`}
                            className={'flex flex-col gap-2 w-8/12'}
                        >
                            <div className={'flex items-center gap-5'}>
                                <div
                                    className={'text-orange-600 border-orange-600 border font-bold rounded-full h-[30px] w-[30px] ' +
                                        'flex items-center justify-center'}
                                >
                                    {step.step}
                                </div>

                                <span className={'font-bold text-lg'}>
                                    {step.title}
                                </span>
                            </div>

                            <div className={''}>
                                {step.description}
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}
