import Image from "next/image";

export default function FeaturedHeadlinesSection() {
    const featuredSections = [
        {
            image: 'https://reassurez-moi.fr/build/images/homepage/trophy.svg',
            alt: 'prix attractifs',
            title: 'Les tarifs les plus bas',
            description: 'Avec des contrats offrants d\'excellentes garanties',
        },
        {
            image: 'https://reassurez-moi.fr/build/images/homepage/giftbox.svg',
            alt: 'experts assurance',
            title: 'Simple et humain',
            description: 'Le meilleur de la technologie avec des experts ultra-disponibles',
        },
        {
            image: 'https://reassurez-moi.fr/build/images/homepage/handshake.svg',
            alt: 'gratuit simple',
            title: '100% gratuit',
            description: 'Nous ne facturons rien et notre service est sans engagement',
        },
    ]

    return (
        <>
            {featuredSections.map((item, index) => {
                return (
                    <div
                        key={`featured-item-${index}`}
                        className={'flex flex-col items-center gap-y-3 md:w-1/4 relative'}
                    >
                        <div className="relative w-16 h-16">
                            <Image
                                className={'object-contain'}
                                src={item.image}
                                alt={item.alt}
                                fill
                                sizes="(max-width: 768px) 40vw, (max-width: 1200px) 40vw, 20vw"
                            />
                        </div>

                        <span className={'text-center text-2xl text-orange-600'}>
                            {item.title}
                        </span>

                        <span className={'text-center'}>
                            {item.description}
                        </span>
                    </div>
                )
            })}
        </>
    )
}
