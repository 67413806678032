import Image from "next/image";

export default function InsurerSection() {

    const insurers = [
        {
            image: '/images/homepage/logos/prevoir.png',
            sizeClass: 'h-20 w-20',
            backgroundColorClass: 'bg-white',
            alt: 'prevoir',
        },
        {
            image: '/images/homepage/logos/malakoffhumanis.png',
            sizeClass: 'h-[60px] w-[60px]',
            backgroundColorClass: 'bg-white',
            alt: 'malakoff humanis',
        },
        {
            image: '/images/homepage/logos/generali.png',
            sizeClass: 'h-16 w-16',
            backgroundColorClass: 'bg-white',
            alt: 'generali assurance',
        },
        {
            image: '/images/homepage/logos/axa.png',
            sizeClass: 'h-[50px] w-[50px]',
            backgroundColorClass: 'bg-[#00188e]',
            alt: 'axa assurance',
        },
        {
            image: '/images/homepage/logos/swisslife.png',
            sizeClass: 'h-16 w-16 -mt-3',
            backgroundColorClass: 'bg-white',
            alt: 'swisslife assurance',
        },
        {
            image: '/images/homepage/logos/serenis.png',
            sizeClass: 'h-[65px] w-[65px]',
            backgroundColorClass: 'bg-white',
            alt: 'serenis assurance',
        },
        {
            image: '/images/homepage/logos/cnp.png',
            sizeClass: 'h-[60px] w-[60px] ml-4',
            backgroundColorClass: 'bg-[#171372]',
            alt: 'cnp assurance',
        },
    ]

    return (
        <>
            <h2 className={'text-xl md:text-3xl font-semibold'}>
                Des assureurs triés sur le volet
            </h2>

            <div className={'flex w-full max-w-5xl gap-5 items-center flex-wrap justify-center md:justify-between px-5 h-full'}>
                {insurers.map((insurer, index) => {
                    return (
                        <div
                            className={`w-20 h-20 overflow-hidden flex items-center justify-center rounded-full border relative object-contain ${insurer.backgroundColorClass}`}
                            key={`featured-insurer-${index}`}
                        >
                            <div className={`relative ${insurer.sizeClass}`}>
                                <Image
                                    className={'object-contain'}
                                    src={insurer.image}
                                    alt={insurer.alt}
                                    fill
                                    sizes="10vw"
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}
