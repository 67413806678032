import IconLinkCard from "@/app/components/atomic/IconLinkCard";

const mainProducts = [
    {
        name: 'Assurance emprunteur',
        link: '/simulation-assurance-pret-immobilier',
        faIcon: 'fa-building-columns',
        alt: 'comparateur prêt immobilier',
        new: false,
    },
    {
        name: 'Mutuelle santé',
        link: '/comparateur-mutuelle-sante',
        faIcon: 'fa-heart-pulse',
        alt: 'comparateur en assurance sante',
        new: false,
    },
    {
        name: 'Prêt immobilier',
        link: '/taux-pret-immobilier',
        faIcon: 'fa-house-chimney-user',
        alt: 'pret immobilier',
        new: false,
    },
    {
        name: 'Assurance Voyage',
        link: '/guide/assurance-voyage',
        faIcon: 'fa-plane-engines',
        alt: 'assurance voyage',
        new: false,
    },
]

export default function HeadProductsSection() {
    return (
        <>
            {mainProducts.map((mainProduct, index) => {
                return (
                    <IconLinkCard
                        key={`product-${index}`}
                        link={mainProduct.link}
                        iconClassName={mainProduct.faIcon}
                        title={mainProduct.name}
                        backgroundColorClass={'bg-blue-100'}
                        outlineColorClass={'outline-blue-300'}
                        heightClass={'h-44'}
                        widthClass={'w-44 md:w-56'}
                        iconSizeClass={'6xl'}
                    />
                )
            })}
        </>
    )
}
